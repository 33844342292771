<template>
  <div v-if="current_data">
    <v-layout class="mt-4 min-height-btn pick-date-style">
      <v-flex d-flex align-center>
        <span class="subheading font-weight-bold">{{ $t('Data collected at') }}</span>
        <span class="subheading" :class="lang === 'en' ? 'ml-3' : 'mr-3'" style="text-decoration: underline">{{ current_data_time }}</span>
        <div id="pick-date" style="position: relative"></div>
        <v-menu
          v-model="pick_data_menu"
          :close-on-content-click="false"
          :nudge-width="240"
          offset-y
          :right="lang === 'en'" 
          :left="lang !== 'en'"
          absolute
          attach="#pick-date"
        >
          <template v-slot:activator="{ on, attrs }">
            <img
              slot="activator"
              src="@/assets/images/cabinet_details_icons/calendar.svg"
              alt="calendar"
              style="height: 20px; width: 20px"
              class="mx-2"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-card class="styled-rounded-card" style="background-color: white !important">
            <v-card-title class="px-3 pt-3 pb-0">
              <span class="subheading font-weight-bold">{{ $t('Pick date & time') }}</span>
              <v-spacer></v-spacer>
              <v-icon style="font-size: 20px" color="black" @click="closePickerMenu">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-2 px-3 pb-3">
              <v-layout d-flex align-end>
                <v-flex xs5>
                  <span class="property-name subheading">{{ $t('Date') }}</span>
                </v-flex>
                <v-flex xs7 :class="lang === 'en' && 'ml-3' || 'mr-3'">
                  <span class="property-name subheading">{{ $t('Time') }}</span>
                </v-flex>
              </v-layout>
              <v-layout d-flex align-end class="date-style">
                <v-flex xs5>
                  <v-menu
                    ref="menu"
                    v-model="show_custom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="custom_date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="custom_date"
                        readonly
                        v-on="on"
                        style="margin-bottom: 1px;"
                        hide-details
                        class="mt-0 pt-0 property-name body-1"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="custom_date" no-title scrollable :max="max_date">
                      <v-spacer></v-spacer>
                      <v-btn flat color="#292F7D" @click="show_custom = false">{{ $t('Cancel') }}</v-btn>
                      <v-btn flat color="#292F7D" @click="$refs.menu.save(custom_date)">{{ $t('Ok') }}</v-btn>
                    </v-date-picker>
                  </v-menu> 
                </v-flex>
                <v-flex xs7 :class="lang === 'en' && 'ml-3' || 'mr-3'">
                  <input
                    v-model="custom_time"
                    @change="setTime"
                    type="time"
                    class="property-name"
                    style="border-bottom: 1px solid #6a6a6a;"
                    required
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-3">
                <v-btn
                  class="text-transform-none"
                  color="#292F7D"
                  outline
                  round
                  :class="lang === 'en' ? 'mr-2' : 'ml-2'"
                  @click="closePickerMenu"
                >
                  <span>{{ $t('Cancel') }}</span>
                </v-btn>
                <v-btn
                  class="text-transform-none white--text"
                  color="#292F7D"
                  round
                  @click="updateCurrentData"
                >
                  <span>{{ $t('Ok') }}</span>
                </v-btn>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-btn
          class="text-transform-none font-weight-bold"
          color="#292F7D"
          outline
          round
          style="border: 2px solid"
          :class="lang === 'en' ? 'mr-2' : 'ml-2'"
          @click="retrieveLatestData"
        >
          <span>{{ $t('Retrieve last data') }}</span>
        </v-btn>
      </v-flex>
      <v-flex d-flex justify-end>
        <v-btn @click="generateExcel" color="#292F7D" outline round style="border: 2px solid">
          <img src="@/assets/images/tondo_icons/export_icon.svg" alt="calendar" style="height: 20px; width: 20px"/>
          <span :class="lang === 'en' ? 'ml-2' : 'mr-2'" class="text-transform-none subheading font-weight-bold">{{ $t('Export') }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-layout
        v-for="(field, index) in pm_fields"
        :key="field.title"
        align-center
        class="styled-rounded-card px-2 my-2"
        style="flex: none; width: 270px; direction: ltr !important"
        :class="index === (pm_fields.length - 1) ? '' : lang === 'en' ? 'mr-3' : 'ml-3'" 
      >
        <v-layout column class="pa-3">
          <v-layout row justify-space-between>
            <span class="subheading font-weight-bold property-name">{{ field.title }}</span>
          </v-layout>
          <v-layout row>
            <v-flex v-for="field_data in field.values" :key="`${field.title}-${field_data.field}`" class="mt-2">
              <h3 class="body-2 font-weight-bold mb-1">{{ current_data[field_data.field] }}</h3>
              <span class="body-2 property-name">{{ field_data.title }}</span>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import Utils from '@/modules/Utils';
import vuex from '@/store';
import PropUtils from '@/modules/PropUtils';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';

export default {
  name: 'DemandsTab',
  props: ['streamData'],
  components: {},
  data() {
    return {
      lastData: null,
      pick_data_menu: false,
      select: 'last',
      time: '',
      openEditTimes: false,
      cabinet_id: null,
      last_data_time: null,
      current_data: null,
      current_data_time: null,
      show_custom: false,
      custom_date: null,
      custom_time: null,
      device: null,
      pm_fields: [
        {title: 'Unbalance', values: [{title: 'Unbalance V', field: 'V-Unbalance'}, {title: 'Unbalance C', field: 'C-Unbalance'}]},
        {title: 'KW', values: [{title: 'L1', field: 'kw_L1', excel: 'KW L1'}, {title: 'L2', field: 'kw_L2', excel: 'KW L2'}, {title: 'L3', field: 'kw_L3', excel: 'KW L3'}]},
        {title: 'V THD', values: [{title: 'V1 V12 THD', field: 'V1_V12_THD'}, {title: 'V2 V23 THD', field: 'V2_V23_THD'}, {title: 'V3 V31 THD', field: 'V3_V31_THD'}]},
        {title: 'I THD', values: [{title: 'I1', field: 'I1_THD', excel: 'I1 THD'}, {title: 'I2', field: 'I2_THD', excel: 'I2 THD'}, {title: 'I3', field: 'I3_THD', excel: 'I3 THD'}]},
        {title: '3P AVR', values: [{title: 'LNLL Voltage', field: '3P-AVR-LNLL-Voltage', excel: '3P AVR LNLL Voltage'}, {title: 'LL Voltage', field: '3P-AVR-LL-Voltage', excel: '3P AVR LL Voltage'}, {title: 'CURRENT', field: '3P-AVR-CURRENT', excel: '3P AVR CURRENT'}]},
        {title: 'V Max DMD', values: [{title: 'V1 Max', field: 'V1_Max_DMD', excel: 'V1 Max DMD'}, {title: 'V2 Max', field: 'V2_Max_DMD',  excel: 'V2 Max DMD'}, {title: 'V3 Max', field: 'V3_Max_DMD', excel: 'V3 Max DMD'}]},
        {title: 'I Max DMD', values: [{title: 'I1 Max', field: 'I1_Max_DMD', excel: 'I1 Max DMD'}, {title: 'I2 Max', field: 'I2_Max_DMD', excel: 'I2 Max DMD'}, {title: 'I3 Max', field: 'I3_Max_DMD', excel: 'I3 Max DMD'}]}
      ],
      prop_utils: PropUtils,
      max_date: null
    };
  },
  beforeMount() {
    const now = moment().utcOffset(0).valueOf();
    this.max_date = moment(now).format('YYYY-MM-DD');
    this.setDefaultCustomData();
    const data = this.streamData[this.streamData.length - 1];
    let parsed_data = {};
    if (typeof data[1] === 'string') {
      parsed_data = JSON.parse(data[1].replaceAll('\'', '\"'));
    }else {
      parsed_data = data[1];
    }
    this.lastData = parsed_data;
    this.last_data_time = moment(this.streamData[this.streamData.length - 1][0]).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
    this.current_data = cloneDeep(this.lastData);
    this.current_data_time = this.last_data_time;
    this.device = this.$store.getters['Reports/commissionedCabinets_map'].get(this.$route.params.id);
    this.cabinet_id = this.device['meta.device'].cabinet_id;
  },
  computed: {
    projectTimezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    lang() {
      return this.$store.state.Global.lang;
    },
    absoluteStreamHistory() {
      return this.$store.state.ProjectStreams.absoluteStreamHistory;
    }
  },
  methods: {
    closePickerMenu(){
      this.pick_data_menu = false;
      this.setDefaultCustomData();
    },
    setDefaultCustomData() {
      const now = moment().utcOffset(0).valueOf();
      this.custom_date = moment(now).format('YYYY-MM-DD');
      this.custom_time = moment(now).format('hh:mm');
    },
    retrieveLatestData(){
      this.current_data = cloneDeep(this.lastData);
      this.current_data_time = this.last_data_time;
    },
    async updateCurrentData() {
      const timestamp_time = moment(`${this.custom_date} ${this.custom_time}`, Utils.getFullDateFormat()).valueOf();
      const before_1_hour = moment(timestamp_time).subtract(1, 'hours').valueOf();
      const parameters = {
        stream_name: 'pm_data_log',
        aggregators: [],
        tags: {
          device_id: [this.device.id]
        },
        start: before_1_hour,
        end: timestamp_time
      };
      await vuex.dispatch('ProjectStreams/geAbsoluteStreamHistory', parameters);
      let data = {};
      if (this.absoluteStreamHistory.length && this.absoluteStreamHistory[0].values && this.absoluteStreamHistory[0].values.length) {
        const pm_data = this.absoluteStreamHistory[0].values.slice(-1)[0];
        this.current_data_time = moment(pm_data[0]).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
        if (typeof pm_data[1] === 'string') {
          try {
            data = JSON.parse(JSON.parse(pm_data[1]).replaceAll('\'', '\"'));
          }catch (e) {
            try {
              data = JSON.parse(pm_data[1].replaceAll('\'', '\"'));
            }catch (e) {
              data = {};
            }
          }
        }else {
          data = pm_data;
        }
      }
      this.current_data = data;
      if (!Object.keys(data).length) {
        this.current_data_time = moment(`${this.custom_date} ${this.custom_time}`, Utils.getFullDateFormat()).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
      }
      this.closePickerMenu();
    },
    setTime(e) {
      this.custom_time = e.target.value;
    },
    openEdit() {
      this.openEditTimes = !this.openEditTimes;
    },
    generateExcel(){
      const workbook = new Excel.Workbook();
      const columns = [
        {header: this.$t('Field'), key: 'field', width: 20},
        {header: this.$t('Value'), key: 'value', width: 15}
      ];
      const data = [];
      this.pm_fields.forEach((field_data) => {
        field_data.values.forEach((value) => {
          data.push({field: value.excel || value.title, value: this.current_data[value.field]});
        });
      });

      const sheet = workbook.addWorksheet(this.$t('Demands'));
      sheet.columns = columns;
      sheet.addRows(data);

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;'});
        saveAs(blob, `Cabinet ${this.cabinet_id} - Demands Data - ${Utils.getNow(this.projectTimezone)}.xlsx`);
      });
    }
  }
};
</script>

<style lang="scss">
.date-style {
  .v-text-field input {
    padding: 8px 0 1px !important;
  }

  .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: #6a6a6a !important;
  }
}
</style>

<style scoped lang="scss">
.property-name {
  color: #6a6a6a;
}

.pick-date-style {
  .v-menu__content.theme--light.menuable__content__active {
    border-radius: 16px;
  }
}

.min-height-btn {
  .v-btn {
    height: 30px;
  }   
}
</style>